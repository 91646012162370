@import 'mixins';
@import 'templates';

.jchart {
	&, &--body {
		width: 100%;
		height: 100%;
	}

	&--donut {
		&--ring {

		}

		&--hole {

		}

		&--segment {
			@extend %graph-segment;

			&-circle {

			}
			&-path {

			}

			&.active {

			}
		}
	}

	&--pie {
		&--segment {
			@extend %graph-segment;
		}
	}

	&--legend {
		&-shape {
			display: inline-block;
			vertical-align: middle;
			width: 2rem;
			height: 2rem;
			@include border-radius(50%);
		}
	}

	/* --- */

	.sr-only {
		position: absolute;
		width: 1px;
		height: 1px;
		padding: 0;
		margin: -1px;
		overflow: hidden;
		clip: rect(0,0,0,0);
		border: 0;
	}
}